import React from "react"
import styled from "styled-components"

const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 300px 1fr 1fr 1fr 1fr 300px;
  grid-template-rows: 1fr;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  > div {
    z-index: 2;
    grid-row: 1 / end;

    &:nth-child(1) {
      grid-column: 1 / 2;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
    &:nth-child(2) {
      grid-column: 2 / 3;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
    &:nth-child(3) {
      grid-column: 3 / 4;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
    &:nth-child(4) {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      grid-column: 4 / 5;
    }
    &:nth-child(5) {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      grid-column: 5 / 6;
    }
    &:nth-child(6) {
      grid-column: 6 / 7;
    }
  }
  @media screen and (max-width: 1600px) {
    grid-template-columns: 300px 1fr 1fr 1fr 300px;
    > div {
      z-index: 2;
      grid-row: 1 / end;

      &:nth-child(1) {
        grid-column: 1 / 2;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }
      &:nth-child(2) {
        grid-column: 2 / 3;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }
      &:nth-child(3) {
        grid-column: 3 / 4;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }
      &:nth-child(4) {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        grid-column: 4 / 5;
      }
      &:nth-child(5) {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        grid-column: 5 / end;
      }
      &:nth-child(6) {
        display: none;
      }
    }
  }
  @media screen and (max-width: 1300px) {
    grid-template-columns: 300px 1fr 1fr 300px;
    > div {
      z-index: 2;
      grid-row: 1 / end;

      &:nth-child(1) {
        grid-column: 1 / 2;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }
      &:nth-child(2) {
        grid-column: 2 / 3;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }
      &:nth-child(3) {
        grid-column: 3 / 4;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }
      &:nth-child(4) {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        grid-column: 4 / 5;
      }
      &:nth-child(5) {
        display: none;
      }
    }
  }
  @media screen and (max-width: 1100px) {
    grid-template-columns: 300px 1fr 300px;
    > div {
      z-index: 2;
      grid-row: 1 / end;

      &:nth-child(1) {
        grid-column: 1 / 2;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }
      &:nth-child(2) {
        grid-column: 2 / 3;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }
      &:nth-child(3) {
        grid-column: 3 / 4;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }
      &:nth-child(4) {
        display: none;
      }
    }
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
    > div {
      z-index: 2;
      grid-row: 1 / end;

      &:nth-child(1) {
        grid-column: 1 / 2;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }
      &:nth-child(2) {
        grid-column: 2 / 3;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }
      &:nth-child(3) {
        display: none;
      }
    }
  }
`

const GridComponent = () => {
  return (
    <Grid>
      {/* <div />
      <div />
      <div />
      <div />
      <div />
      <div /> */}
    </Grid>
  )
}

export default GridComponent
