import React from "react"
import styled from "styled-components"
import { HomeImage } from "../../components/theme"
import GridComponent from "../GridComponent"

const Container = styled.div`
  position: relative;
`
const HomeContainer = styled.div`
  width: 100%;
  height: 170px;
  overflow: hidden;
  position: relative;
  z-index: 4;
  @media screen and (max-width: 1200px) {
    height: 200px;
  }
`

const HomeImageSection = styled.div`
  /* grid-column: 1 / end;
  grid-row: 1 / end; */
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const HeaderSection = props => {
  return (
    <Container>
      <GridComponent />
      <HomeImageSection>
        <HomeImage src={props.back} alt="" />
      </HomeImageSection>
      <HomeContainer />
    </Container>
  )
}

export default HeaderSection
