import React from "react"
import styled from "styled-components"
import { Pt } from "../theme"
import Clock from "../../assets/time.svg"
import Weights from "../../assets/dumbbell.svg"
import Bolt from "../../assets/thunderbolt.svg"
import Head from "../../assets/head.svg"

const Container = styled.div`
  padding: 100px 160px 0;
  @media screen and (max-width: 850px) {
    padding: 50px 100px 0 100px;
  }
  @media screen and (max-width: 700px) {
    padding: 50px 50px 0 50px;
  }
  /* @media screen and (max-width: 450px) {
    padding: 50px 100px 0 100px;
  } */
`

const Icons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  svg {
    width: 100px;
    path {
      fill: white;
    }
  }
  @media screen and (max-width: 850px) {
    svg {
      width: 50px;
    }
  }
`

const Item = styled.div`
  /* /* width: 100px; */
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 20px;
    font-size: 20px;
  }
  @media screen and (max-width: 850px) {
    p {
      font-size: 15px;
    }
  }
`

const IconsSection = () => {
  return (
    <Container>
      <Icons>
        <Item>
          <Clock />
          <Pt>Štedi vreme</Pt>
        </Item>
        <Item>
          <Weights />
          <Pt>Topi masti</Pt>
        </Item>
        <Item>
          <Bolt />
          <Pt>Jača mišiće</Pt>
        </Item>
        <Item>
          <Head />
          <Pt>Jača psihu</Pt>
        </Item>
      </Icons>
    </Container>
  )
}

export default IconsSection
