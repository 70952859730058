import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextSection from "../components/sections/TextSection"
import HeaderSpace from "../components/sections/HeaderSpace"
import IconsSection from "../components/sections/IconsSection"
import Image from "../images/back2.png"
import Back from "../images/ems.jpg"

const Mihabodytec = () => (
  <Layout>
    <SEO title="Ems Tehnologija" />
    <HeaderSpace back={Back} />
    <TextSection
      id="ems"
      headline="Miha Bodytec"
      par1="EMS je savremena tehnologija koja funkcioniše na principu naizmenične struje niskog inteziteta koja
        dubinski stimuliše mišiće prilikom vežbanja. Fast Fit koristi EMS tehnologiju renomiranog nemačkog
        proizvođača Miha Bodytec."
      par2="Elektro stimulacija čitave muskulature sa Miha Bodytec-om rezultira intenzivnom kontrakcijom mišića
        koju je teško postići samo treningom sa tegovima. 20 minuta čistog rada mišića, bez naprezanja tetiva i
        zglobova, direktno rezultira povećanim sagorevanjem kalorija, gubitkom težine, smanjenjem celulita,
        poboljšanjem opšteg stanja organizma, povećanjem izdržljivosti, snage i vitalnosti."
      img={Image}
    />
    <IconsSection />
  </Layout>
)

export default Mihabodytec
