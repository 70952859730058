import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import GridComponent from "../../components/GridComponent"
import { H2, P, options } from "../../components/theme"

const Container = styled.div`
  position: relative;
  z-index: 0;
  overflow: hidden;
`

const BackSection = styled.div`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #191919;
`

const TextContent = styled.div`
  width: 100%;
  /* height: 500px; */
  z-index: 2;
  /* padding: 100px 0; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* max-height: 500px; */
  @media screen and (max-width: 900px) {
    height: auto;
    flex-direction: column;
    justify-content: center;
  }
`

const Left = styled.div`
  z-index: 3;
  flex-basis: 50%;
  padding: 50px 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1500px) {
    padding: 50px;
  }
  @media screen and (max-width: 900px) {
    text-align: center;
  }
  @media screen and (max-width: 550px) {
    padding: 20px;
  }
`

const Right = styled.div`
  flex-basis: 50%;
  overflow: hidden;
  width: 50vw;
  display: flex;
  justify-content: center;
  img {
    float: right;
    margin: 0;
  }
  @media screen and (max-width: 900px) {
    flex-basis: 100%;
    width: 100%;
    display: block;
    /* justify-content: center; */
  }
`

const Span = styled.span`
  a {
    color: ${options.turquoise};
    font-weight: bold;
    text-decoration: none;
  }
`

const TextSection = props => {
  return (
    <Container>
      <GridComponent />
      <BackSection />
      <TextContent id={props.id}>
        <Left>
          <H2>{props.headline}</H2>
          <P>
            {props.par1}{" "}
            <Span>
              <Link to="Mihabodytec">{props.span}</Link>
            </Span>{" "}
            {props.par3}
          </P>
          <P>{props.par2}</P>
          <P></P>
        </Left>
        <Right>
          <img className="testImage" src={props.img} alt="" />
        </Right>
      </TextContent>
    </Container>
  )
}

export default TextSection
